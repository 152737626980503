import { Toaster, useToast } from '@conkoa/ui/toaster'
import { TooltipProvider } from '@conkoa/ui/tooltip'
import bodyFontStyleSheet from '@fontsource/inter/latin.css?url'
import titleFontStyleSheet from '@fontsource/rajdhani/latin.css?url'
import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node'
import { json } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useSearchParams,
} from '@remix-run/react'
import { sql } from 'drizzle-orm'
import { LazyMotion, domAnimation } from 'framer-motion'
import posthog from 'posthog-js'
import { useEffect } from 'react'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import * as gtag from '~/utils/gtags.client'
import { GeneralErrorBoundary } from './components/error-boundary'
import { EpicProgress } from './components/progress-bar'
import { SITE_DESCRIPTION, SITE_TITLE } from './constants'
import { useTheme } from './routes/api.theme-change'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { logout } from './utils/auth/authentication.server' // fix: prevents edge runtime
import { getTeamId, getUserId } from './utils/auth/authorization.server'
import { ClientHintCheck, getHints } from './utils/client-hints'
import { db } from './utils/db.server'
import {
  roleTable,
  roleToUserTable,
  subscriptionTable,
  teamMemberTable,
  teamTable,
  userImageTable,
  userTable,
} from './utils/db/schema'
import { getEnv } from './utils/env.server'
import { honeypot } from './utils/honeypot.server'
import { combineHeaders, getDomainUrl } from './utils/misc'
import type { Theme } from './utils/theme.server'
import { getTheme } from './utils/theme.server'
import { makeTimings, time } from './utils/timing.server'
import { getToast } from './utils/toast.server'

export const links: LinksFunction = () => {
  return [
    // Preload favicon and hero image as a resource to avoid render blocking
    { rel: 'preload', href: '/favicon.svg', as: 'image' },
    { rel: 'preload', href: '/favicon-180.png', as: 'image' },
    // Preload CSS as a resource to avoid render blocking
    { rel: 'preload', href: titleFontStyleSheet, as: 'style' },
    { rel: 'preload', href: bodyFontStyleSheet, as: 'style' },
    { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
    // Load assets
    { rel: 'stylesheet', href: titleFontStyleSheet },
    { rel: 'stylesheet', href: bodyFontStyleSheet },
    // //These should match the css preloads above to avoid css as render blocking resource
    { rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' },
    { rel: 'icon', href: '/favicon-32.png', sizes: '32x32' },
    { rel: 'icon', href: '/favicon-128.png', sizes: '128x128' },
    { rel: 'icon', href: '/favicon-180.png', sizes: '180x180' },
    { rel: 'icon', href: '/favicon-192.png', sizes: '192x192' },
    { rel: 'apple-touch-icon', type: 'image/png', href: '/favicon-180.png' },
    { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  ].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? SITE_TITLE : `Error | ${SITE_TITLE}` },
    { name: 'description', content: SITE_DESCRIPTION },
  ]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader')
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root',
  })
  const teamId = await time(() => getTeamId(request, userId), {
    timings,
    type: 'getTeamId',
    desc: 'getTeamId in root',
  })

  const user = userId
    ? await time(
        async () => {
          const [userRoles, users] = await Promise.all([
            db
              .select({ name: roleTable.name })
              .from(roleToUserTable)
              .leftJoin(
                roleTable,
                sql`${roleTable.id} = ${roleToUserTable.roleId}`,
              )
              .where(sql`${roleToUserTable.userId} = ${userId}`),
            db
              .select({
                id: userTable.id,
                name: userTable.name,
                email: userTable.email,
                image: { id: userImageTable.id, url: userImageTable.url },
                stripeSubscription: {
                  currentPeriodEnd: subscriptionTable.currentPeriodEnd,
                  currentPeriodStart: subscriptionTable.currentPeriodStart,
                  status: subscriptionTable.status,
                },
              })
              .from(userTable)
              .leftJoin(
                userImageTable,
                sql`${userImageTable.userId} = ${userId}`,
              )
              .leftJoin(
                teamMemberTable,
                sql`${teamMemberTable.userId} = ${userId}`,
              )
              .leftJoin(
                teamTable,
                sql`${teamTable.ownerId} = ${userId} OR ${teamMemberTable.teamId} = ${teamTable.id}`,
              )
              .leftJoin(
                subscriptionTable,
                sql`${subscriptionTable.teamId} = ${teamTable.id}`,
              )
              .where(sql`${userTable.id} = ${userId}`)
              .limit(1),
          ])
          const user = users[0]
          if (!user) return null

          return { ...user, roles: userRoles }
        },
        { timings, type: 'find user', desc: 'find user in root' },
      )
    : null

  const team = teamId
    ? await time(
        async () => {
          const [team] = await db
            .select({
              id: teamTable.id,
              name: teamTable.name,
              stripeSubscription: {
                currentPeriodEnd: subscriptionTable.currentPeriodEnd,
                currentPeriodStart: subscriptionTable.currentPeriodStart,
                status: subscriptionTable.status,
              },
              createdAt: teamTable.createdAt,
              ownerId: teamTable.ownerId,
            })
            .from(teamTable)
            .leftJoin(
              subscriptionTable,
              sql`${subscriptionTable.teamId} = ${teamId}`,
            )
            .where(sql`${teamTable.id} = ${teamId}`)
            .limit(1)
          return team
        },
        { timings, type: 'find team', desc: 'find team in root' },
      )
    : null

  if ((userId && !user) || (teamId && !team)) {
    console.info('something weird happened')
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' })
  }

  const { toast, headers: toastHeaders } = await getToast(request)
  const honeyProps = honeypot.getInputProps()

  return json(
    {
      user,
      team,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      toast,
      honeyProps,
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
      ),
    },
  )
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
  }
  return headers
}

function Document({
  children,
  theme = 'light',
  env = { MODE: 'development' },
}: {
  children: React.ReactNode
  theme?: Theme
  env?: Partial<ReturnType<typeof getEnv>>
}) {
  const gaTrackingId = env.GA_TRACKING_ID

  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
      <head>
        <ClientHintCheck />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="min-h-full w-full bg-background text-foreground antialiased">
        {process.env.NODE_ENV !== 'production' || !gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const data = useLoaderData<typeof loader>()
  const theme = useTheme()
  useToast(data.toast)

  // monitoring page views
  const location = useLocation()
  const user = data.user
  const userId = user?.id
  const userEmail = user?.email
  const team = data.team
  const teamId = team?.id
  const teamName = team?.name
  const userName = user?.name
  const teamSubscriptionStatus = team?.stripeSubscription?.status

  const [searchParams, setSearchParams] = useSearchParams()
  const shouldIdentify = searchParams.get('identify') === 'true'
  const gaTrackingId = data.ENV.GA_TRACKING_ID

  useEffect(() => {
    if (gaTrackingId?.length && ENV.MODE === 'production') {
      gtag.pageview(location.pathname, gaTrackingId)
    }
  }, [location, gaTrackingId])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (ENV.MODE !== 'production') {
      return
    }
    posthog.capture('$pageview', {})
  }, [location])

  useEffect(() => {
    if (ENV.MODE !== 'production') {
      setSearchParams((old) => {
        old.delete('identify')
        return old
      })
      return
    }
    if (shouldIdentify && userId && userEmail) {
      // identify the user
      posthog.identify(userEmail, {
        userId,
        name: userName,
      })

      // identify the team
      if (teamId && teamName) {
        posthog.group('company', teamId, {
          name: teamName,
          subscription_status: teamSubscriptionStatus,
        })
      }
      // reset the search params
      setSearchParams((old) => {
        old.delete('identify')
        return old
      })
    }
  }, [
    userId,
    userEmail,
    userName,
    shouldIdentify,
    setSearchParams,
    teamId,
    teamName,
    teamSubscriptionStatus,
  ])

  return (
    <Document theme={theme} env={data.ENV}>
      <Outlet />
      <Toaster theme={theme} closeButton position="bottom-right" richColors />
      <EpicProgress />
    </Document>
  )
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <LazyMotion features={domAnimation}>
        <TooltipProvider>
          <App />
        </TooltipProvider>
      </LazyMotion>
    </HoneypotProvider>
  )
}

export default AppWithProviders

export function ErrorBoundary() {
  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document>
      <GeneralErrorBoundary />
    </Document>
  )
}
